/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var $gridProjects;
var $conProjects;

var $gridNews;
var $conNews;

function applyFilter(){

  var string = window.location.search.substring();
  var filter = '.post-type-'+string.substring(string.indexOf('filter')+7);

  jQuery('.filterbutton').removeClass('current');
  jQuery('.filterbutton[data-filter="'+filter+'"]').addClass('current');

  if(filter === '.post-type-alle') {
    filter = '*';
  }

  $gridProjects.isotope({
     filter: filter,
     animationOptions: {
       duration: 750,
       easing: 'linear',
       queue: false
     }
  });

  //jQuery("li.filterbutton").removeClass('current');
  //jQuery("li.filterbutton").find("[data-filter='" + filter + "']").addClass('current');
  // jQuery('.filter-button').addClass('current');
}


function applyFilterNews(){

  var string = window.location.search.substring();
  var filter = '.post-type-'+string.substring(string.indexOf('filter')+7);

  jQuery('.filterbutton').removeClass('current');
  jQuery('.filterbutton[data-filter="'+filter+'"]').addClass('current');

  if(filter === '.post-type-alle') {
    filter = '*';
  }

  $gridNews.isotope({
     filter: filter,
     animationOptions: {
       duration: 750,
       easing: 'linear',
       queue: false
     }
  });

  //jQuery("li.filterbutton").removeClass('current');
  //jQuery("li.filterbutton").find("[data-filter='" + filter + "']").addClass('current');
  // jQuery('.filter-button').addClass('current');
}




function loadVisible($els, trigger) {

  $els.filter(function () {
      var rect = this.getBoundingClientRect();
      return rect.top >= 0 && rect.top <= window.innerHeight;
  }).trigger(trigger);
}

// mobile menu toggle
(function($) {
  $('#mobile_menu_toggle').on('click', function (e) {
    e.preventDefault();
    $('#mobile_menu').toggleClass('menu-open');
    if($('#mobile_menu').hasClass('menu-open')) {
      $('body').addClass('overflow-hidden');
    }
    else {
      $('body').removeClass('overflow-hidden');
    }
  });

  $(window).on('resize', function () {
    // console.log($(window).width(), window.outerWidth, document.body.scrollWidth, $(window).outerWidth());
    if($(window).width() > 767) {
      $('#mobile_menu').removeClass('menu-open');
      $('body').removeClass('overflow-hidden');
    }
  });


})(jQuery);
// end mobile menu toggle

// footer functions
(function($) {
  $('#show_newsletter_input').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $('#input_container').toggleClass('active');
  });


  $('#newsletter_join').on('submit', function (e) {
    // e.preventDefault();
    $('button', this).addClass('working').prop('disabled',true);

    function displayMessage (message) {

      $('#message').text(message).fadeIn(300).delay(3000).fadeOut(300);
      $('#show_newsletter_input').fadeOut(300).delay(3000).fadeIn(300);
    }

    var _self = $(this);
    $.ajax({
      url: _self.attr('action'),
      data: _self.serialize(),
      type: 'post',
      dataType: 'json',
      success: function(ret){
        console.log(ret);

        if(ret.code === 200){

          $('#newsletter_submit_button').removeClass('working').prop('disabled',false);


          displayMessage( "Takk, du er registert og vil fra nå av motta nyhetsbrev fra oss." );

        }
      },
      error: function(jqXHR, textStatus, errorThrown){
        console.log(jqXHR);

        $('#newsletter_submit_button').removeClass('working').prop('disabled',false);


        $("#message").css("display", "block");
        if(jqXHR.status === 409){
          // E-post-adressen finnes allerede i MailChimp-listen
          displayMessage( "Denne eposten er allerede registert!" );
          //alert('Denne e-posten er allerede registert.');
        } else if(jqXHR.status === 422){
          // Generell feil, forsøk igjen
          displayMessage( "E-post mangler." );
          //alert('Registreringen feilet. Vennligst prøv igjen.');
        } else if(jqXHR.status === 400){
          // Generell feil, forsøk igjen

          displayMessage( "Registreringen feilet. Vennligst prøv igjen." );
          //alert('Registreringen feilet. Vennligst prøv igjen.');
        } else {
          console.log('Unknown error occured in form post, see "mailchimp" node in return.');
          displayMessage( "Registreringen feilet. Vennligst prøv igjen." );
          //alert('Registreringen feilet. Vennligst prøv igjen.');
        }
      }
    });
    return false;
  });



})(jQuery);
// end footer functions

////////////////////// Alle prosjektene kjører følgende:
(function($) {

    function isProjectPage () {
    var pageNames = [
                    'single-konkurranser',
                    'single-parker',
                    'single-byplan',
                    'single-gater-plasser',
                    'single-hager',
                    'single-gater',
                    'single-promenader',
                    'single-uteserveringer',
                    'single-boligparker',
                    'single-institusjonsparker',
                    'single-naeringsparker',
                    'single-gaardsrom'
                  ];

    for (var i = 0; i < pageNames.length; i++) {
      //console.log(pageNames[i] + ' ' + $('body').hasClass(pageNames[i]));
      if($('body').hasClass(pageNames[i])) {
        return true;
      }
    }

    return false;
  }

    function isNewsPage () {
    var pageNames = [
                    'single-aktuelt'

                  ];

    for (var i = 0; i < pageNames.length; i++) {
      //console.log(pageNames[i] + ' ' + $('body').hasClass(pageNames[i]));
      if($('body').hasClass(pageNames[i])) {
        return true;
      }
    }

    return false;
  }





function projectsingle () {

  $('.grid-project').imagesLoaded( function() {

  $('.grid-project').isotope({
      percentPosition: true,
      masonry: {
        gutter: 40
      }
  });
})

  new WOW().init();

  var $win = $(window),
  $imgs = $("a.lazy"),
  $conGallery = $('.grid-gallery').isotope({
    //layoutMode: 'fitRows',
    percentPosition: true,
    columnWidth: '.grid-sizer-gallery',
    itemSelector: '.grid-item-gallery',

    masonry: {

    }
  });


  $conGallery.isotope('on', 'layoutComplete', function () {
    loadVisible($imgs, 'lazylazy');
  });

  $conGallery.imagesLoaded()
    .always( function( instance ) {
      console.log('all images loaded');
      $conGallery.isotope('layout');
     //console.log($conGallery);

    })
    .done( function( instance ) {
      //console.log('all images successfully loaded');
      $conGallery.isotope('layout');
    })
    .fail( function() {
      //console.log('all images loaded, at least one is broken');
      $conGallery.isotope('layout');
    })
    .progress( function( instance, image ) {
      var result = image.isLoaded ? 'loaded' : 'broken';
      console.log( 'image is ' + result + ' for ' + image.img.src );
      $conGallery.isotope('layout');
  });




  $win.on('scroll', function () {
      loadVisible($imgs, 'lazylazy');
  });

  $imgs.lazyload({
      failure_limit: Math.max($imgs.length - 1, 0),
      event: 'lazylazy',
      effect : "fadeIn"
  });


    /*
    // init Masonry
    var $gridGallery = $('.grid-gallery').masonry({
      itemSelector: '.grid-item-gallery',
      percentPosition: true,
      columnWidth: '.grid-sizer-gallery'
    });
    // layout Isotope after each image loads
    $gridGallery.imagesLoaded().progress( function() {
      $grid.masonry();
    });

*/


    $('.gallery-wrapper').magnificPopup({
      delegate: 'a', // child items selector, by clicking on it popup will open
      type: 'image',
      gallery:{enabled:true}
      // other options
    });



}


function newssingle () {

  $('.grid-news').isotope({
     percentPosition: true,
  masonry: {
    gutter: 40
  }
  });

  new WOW().init();

  var $win = $(window),
  $imgs = $("a.lazy"),
  $conGallery = $('.grid-gallery').isotope({
    //layoutMode: 'fitRows',
    percentPosition: true,
    columnWidth: '.grid-sizer-gallery',
    itemSelector: '.grid-item-gallery',

    masonry: {

    }
  });


  $conGallery.imagesLoaded()
    .always( function( instance ) {
      //console.log('all images loaded');
      $conGallery.isotope('layout');
      console.log($conGallery);

    })
    .done( function( instance ) {
      //console.log('all images successfully loaded');
      $conGallery.isotope('layout');
    })
    .fail( function() {
      //console.log('all images loaded, at least one is broken');
      $conGallery.isotope('layout');
    })
    .progress( function( instance, image ) {
      var result = image.isLoaded ? 'loaded' : 'broken';
      //console.log( 'image is ' + result + ' for ' + image.img.src );
      $conGallery.isotope('layout');
  });



  $conGallery.isotope('on', 'layoutComplete', function () {
    loadVisible($imgs, 'lazylazy');
  });

  $win.on('scroll', function () {
      loadVisible($imgs, 'lazylazy');
  });

  $imgs.lazyload({
      failure_limit: Math.max($imgs.length - 1, 0),
      event: 'lazylazy',
      effect : "fadeIn"
  });

}



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var isDraggable;
        var md = new MobileDetect(window.navigator.userAgent);

        if (md.mobile() || md.tablet()) {

          isDraggable = false;

        } else {

          isDraggable = true;

        }


        if(isProjectPage()) {
          projectsingle();

        }

         if(isNewsPage()) {
          newssingle();

        }

        $('.sub-menu').addClass('dropdown-menu');

        /*
        $('.nav li.dropdown').hover(function() {
            $(this).addClass('open');
        }, function() {
            $(this).removeClass('open');
        });
        */

        var isSticky = false;

        $(window).scroll(function() {
            if ($(this).scrollTop() > 1){

              $('.menu-top').addClass("sticky");
              $('#header_placeholder').css({display: 'block'});

            }
            else {
                //$('.menu-top').removeClass("sticky");
                //$('#header_placeholder').css({display: 'none'});
            }
        });


        $('.navbar .dropdown').hover(function() {
          $(this).find('.dropdown-menu').first().stop(true, true).delay(0).slideDown(250);

        }, function() {
            $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp(250);

        });

        $('.navbar .dropdown > a').click(function(){
          location.href = this.href;
        });



        $('.grid-article').isotope({
            itemSelector: '.grid-item',
            masonry: {
              columnWidth: '.col-md-6'
            }
          });





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        new WOW().init();

        var $win = $(window),
        $imgs = $("div.lazy"),
        $con = $('.grid').isotope({
          //layoutMode: 'fitRows',
          itemSelector: '.grid-item',
          masonry: {

            gutter: 40

          }
        });

        $con.isotope('on', 'layoutComplete', function () {
          loadVisible($imgs, 'lazylazy');
        });

        $win.on('scroll', function () {
            loadVisible($imgs, 'lazylazy');
        });

        $imgs.lazyload({
            failure_limit: Math.max($imgs.length - 1, 0),
            event: 'lazylazy'
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },




    // About page
    'om_oss': {
      init: function() {
        // JavaScript to be fired on the home page

        new WOW().init();

        $('.grid').isotope({
            itemSelector: '.ansatt-item',
            masonry: {
              gutter: 40
            }
          });

        $(".about-images-line>img").each(function(i, img) {
            $(img).css({
                position: "relative",
                left: ($(img).parent().width()/2) - ($(img).width()/2)
            });
        });



        var md = new MobileDetect(window.navigator.userAgent);

        if (md.mobile() || md.tablet()) {

          $(".color-overlay").css({display: 'none'});
          $(".ansatte-profilbilde").find('img').css({'filter': 'contrast(1)', 'filter': 'brightness(1)'});

        }



      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    // Contact page
    'kontakt': {
      init: function() {
        // JavaScript to be fired on the home page



        new WOW().init();

        $('.grid').isotope({
            itemSelector: '.ansatt-item',
            masonry: {
              gutter: 40
            }
          });

        $(".about-images-line>img").each(function(i, img) {
            $(img).css({
                position: "relative",
                left: ($(img).parent().width()/2) - ($(img).width()/2)
            });
        });


        var md = new MobileDetect(window.navigator.userAgent);

        if (md.mobile() || md.tablet()) {

          $(".color-overlay").css({display: 'none'});
          $(".ansatte-profilbilde").find('img').css({'filter': 'contrast(1)', 'filter': 'brightness(1)'});

        }



      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },




    // Prosjekter page
    'prosjekter': {

      init: function() {
        // JavaScript to be fired on the prosjekter page

        new WOW().init();

        $(window).scroll(function() {

            if ($(this).scrollTop() > 30){

                $('.filter-wrapper').addClass("stickyFilter");
                $('#filter_placeholder').css({display: 'block'});
            }

            else {

                $('.filter-wrapper').removeClass("stickyFilter");
                $('#filter_placeholder').css({display: 'none'});

            }

        });

        if($(window).width() < 768) { $('.filter-menu').addClass('mobile'); console.log($(window).width());}

        $(window).on('resize', function () {
          if($(window).width() > 767) {
            $('.filter-menu').removeClass('mobile');
          }
          else {
            $('.filter-menu').addClass('mobile');
          }
        });


        window.onpopstate = function(event) {
           if(event && event.state) {
               // event.state.foo
               applyFilter();

           }
        };


        var $win = $(window),
        $imgs = $("div.lazy"),
        $conProjects = $('.grid').isotope({
          layoutMode: 'fitRows',
          itemSelector: '.grid-item',
          fitRows: {
            gutter: 40
          }
        });

        $gridProjects = $('.grid').isotope({
          layoutMode: 'fitRows',
          itemSelector: '.grid-item',
          fitRows: {
            gutter: 40
          }
        });


        $conProjects.isotope('on', 'layoutComplete', function () {
          loadVisible($imgs, 'lazylazy');
        });

        $win.on('scroll', function () {
            loadVisible($imgs, 'lazylazy');
        });

        $imgs.lazyload({
            failure_limit: Math.max($imgs.length - 1, 0),
            event: 'lazylazy'
        });

        $(window).scroll();





        /*
        $grid = $('.grid').isotope({

          itemSelector: '.grid-item',
          masonry: {
            columnWidth: '.col-md-4',
          }
        });
        */

        // bind filter button click
        $('.filter-menu').on('click', '.filterbutton', function() {

          $('.filter-menu').removeClass('open');

          var filterValue = $(this).attr('data-filter');
          var filter = filterValue.substring(11);
          var upperCaseFilter = filter.substr(0,1).toUpperCase()+filter.substr(1);
          $(".filter-toggle").html("Filter ("+upperCaseFilter+") <span class='glyphicon glyphicon-triangle-bottom'>" );

          $('ul li.current').removeClass('current');
          $(this).closest('li').addClass('current');

          window.history.pushState({"html":"","pageTitle":""},"", '?filter='+filter);

          if(filter === 'alle') {
            filterValue = '*';
          }

          $conProjects.isotope({
            filter: filterValue
          });
        });

        $('.filter-toggle').on('click', function (e) {
          var $parentFilterMenu = $(this).closest('.filter-menu');
          $parentFilterMenu.toggleClass('open');

          // if($parentFilterMenu.hasClass('open')) {
          //   $parentFilterMenu.find('ul')
          // }
          // else {

          // }
        });



      },

      finalize: function() {

        applyFilter();

      }

    },


    // Aktuelt page
    'aktuelt': {

      init: function() {
        // JavaScript to be fired on the prosjekter page

        $(window).scroll(function() {

            if ($(this).scrollTop() > 30){

                $('.filter-wrapper').addClass("stickyFilter");
                $('#filter_placeholder').css({display: 'block'});
            }

            else {

                $('.filter-wrapper').removeClass("stickyFilter");
                $('#filter_placeholder').css({display: 'none'});

            }

        });

        if($(window).width() < 768) { $('.filter-menu').addClass('mobile'); console.log($(window).width());}

        $(window).on('resize', function () {
          if($(window).width() > 767) {
            $('.filter-menu').removeClass('mobile');
          }
          else {
            $('.filter-menu').addClass('mobile');
          }
        });

        window.onpopstate = function(event) {
           if(event && event.state) {
               // event.state.foo
               applyFilter();

           }
        };


        var $win = $(window),
        $imgs = $("div.lazy"),
        $conNews = $('.newsGrid').isotope({
          //layoutMode: 'fitRows',
          itemSelector: '.grid-item-news',
          masonry: {

          }
        });

        $gridNews = $('.newsGrid').isotope({
          //layoutMode: 'fitRows',
          itemSelector: '.grid-item-news',
          masonry: {

          }
        });


        $conNews.isotope('on', 'layoutComplete', function () {
          loadVisible($imgs, 'lazylazy');
        });

        $win.on('scroll', function () {
            loadVisible($imgs, 'lazylazy');
        });

        $imgs.lazyload({
            failure_limit: Math.max($imgs.length - 1, 0),
            event: 'lazylazy'
        });

        $(window).scroll();


        // bind filter button click

        $('.filter-menu').on('click', '.filterbutton', function() {
          $('.filter-menu').removeClass('open');
          var filterValue = $(this).attr('data-filter');
          var filter = filterValue.substring(11);
          var upperCaseFilter = filter.substr(0,1).toUpperCase()+filter.substr(1);
          $(".filter-toggle").html("Filter ("+upperCaseFilter+") <span class='glyphicon glyphicon-triangle-bottom'>" );

          $('ul li.current').removeClass('current');
          $(this).closest('li').addClass('current');

          window.history.pushState({"html":"","pageTitle":""},"", '?filter='+filter);

          if(filter === 'alle') {
            filterValue = '*';
          }

          $conNews.isotope({
            filter: filterValue
          });
        });


        $('.filter-toggle').on('click', function (e) {
          var $parentFilterMenu = $(this).closest('.filter-menu');
          $parentFilterMenu.toggleClass('open');

          // if($parentFilterMenu.hasClass('open')) {
          //   $parentFilterMenu.find('ul')
          // }
          // else {

          // }
        });



      },

      finalize: function() {

        applyFilterNews();

      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };




  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);




})(jQuery); // Fully reference jQuery after this point.


























